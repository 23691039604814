import { BaseModel } from "./base.model";
import { Translatable } from "./translatable.model";

export class CardItem extends BaseModel {
  constructor(
    public type: string,
    public active: boolean,
    public title: Translatable,
    public description?: Translatable,
    public category?: Translatable,
    public image?: string,
    public url?: string,
    public date?: string,
    public openBlank?: boolean
  ) {
    super();
  }
}
