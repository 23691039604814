<template>
  <div>
    <h1>{{ title }}</h1>
    <b-button @click="add()" class="mb-3">{{ addText }}</b-button>
    <vuetable ref="cardtable" :api-mode="false" :fields="fields" :data="object">
      <template slot="actions" scope="props">
        <font-awesome-icon
          v-if="props.rowIndex > 0"
          icon="arrow-up"
          data-toggle="tooltip"
          data-placement="top"
          title="Move up"
          @click="moveUp(props.rowIndex)"
          class="action-icon"
        ></font-awesome-icon>
        <font-awesome-icon
          v-if="props.rowIndex < object.length - 1"
          icon="arrow-down"
          data-toggle="tooltip"
          data-placement="top"
          title="Move down"
          @click="moveDown(props.rowIndex)"
          class="ml-2 action-icon"
        ></font-awesome-icon>
        <font-awesome-icon
          icon="copy"
          data-toggle="tooltip"
          data-placement="top"
          title="Duplicate slide"
          @click="duplicate(props.rowIndex)"
          class="ml-2 action-icon"
        ></font-awesome-icon>
        <font-awesome-icon
          icon="edit"
          data-toggle="tooltip"
          data-placement="top"
          title="Edit slide"
          @click="edit(props.rowIndex)"
          class="ml-2 action-icon"
        ></font-awesome-icon>
        <font-awesome-icon
          icon="trash"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove slide"
          class="ml-2 action-icon"
          @click="showDeleteModal(props.rowIndex)"
        ></font-awesome-icon>
      </template>
    </vuetable>
    <TableDetail
      v-model="showModal"
      :object="object"
      :type="type"
      :selected="selected"
      :index="selectedIndex"
      :title="detailTitle"
    />
  </div>
</template>
<script>
import TableDetail from "@/components/admin/home/TableDetail.vue";
import Vuetable from "vuetable-2";
import { CarouselSlide } from "@/models/slide.model";
import { CardItem } from "@/models/card-item.model";
import Vue from "vue";

export default {
  props: ["object", "type", "title", "addText", "detailTitle", "fields"],
  components: {
    Vuetable,
    TableDetail,
  },
  data() {
    return {
      selected: "",
      selectedIndex: null,
      showModal: false,
      blockIndex: null,
      confirmBox: "",
    };
  },
  methods: {
    edit(index) {
      this.selectedIndex = index;
      this.selected = this.object[index];
      this.toggleModal();
    },
    duplicate(index) {
      this.object.push(JSON.parse(JSON.stringify(this.object[index])));
    },
    add() {
      this.selectedIndex = null;
      if (this.type == "items") {
        this.selected = new CardItem(
          "card",
          true,
          {},
          {},
          {},
          "",
          "",
          "",
          true
        );
      } else if (this.type == "slides") {
        this.selected = new CarouselSlide({}, "", {}, {}, {});
      }
      this.toggleModal();
    },
    showDeleteModal(index) {
      this.$bvModal
        .msgBoxConfirm(this.$i18n.t("deletePrompt"))
        .then((value) => {
          if (value) {
            this.object.splice(index, 1);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    moveUp(index) {
      const el1 = this.object[index];
      Vue.set(this.object, index, this.object[index - 1]);
      Vue.set(this.object, index - 1, el1);
    },
    moveDown(index) {
      const el1 = this.object[index];
      Vue.set(this.object, index, this.object[index + 1]);
      Vue.set(this.object, index + 1, el1);
    },
  },
};
</script>
<style lang="scss">
$color-secondary: var(--secondary);

.action-icon:hover {
  color: $color-secondary;
  cursor: pointer;
}
</style>
