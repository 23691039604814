<template>
  <div>
    <b-tabs content-class="mt-3" nav-class="no-padding">
      <b-tab v-for="(language, index) in languages" :key="index">
        <template #title>
          <country-flag :country="language" size="normal" />
        </template>
        <b-form-group label="Title">
          <b-form-input
            name="title"
            v-validate="'required|min:3|max:50'"
            v-model="slide.title[language]"
          ></b-form-input>
          <div v-if="submitted && errors.has('title')" class="alert-danger">
            {{ errors.first("title") }}
          </div>
        </b-form-group>
        <b-form-group label="Description">
          <b-form-textarea
            name="Description"
            v-validate="'required|min:3|max:50'"
            v-model="slide.description[language]"
          ></b-form-textarea>
          <div
            v-if="submitted && errors.has('description')"
            class="alert-danger"
          >
            {{ errors.first("description") }}
          </div>
        </b-form-group>
        <b-form-group :label="$t('buttonText')">
          <b-form-input
            name="button-text"
            v-validate="'required|min:3|max:50'"
            v-model="slide.button_text[language]"
          ></b-form-input>
          <div
            v-if="submitted && errors.has('button_text')"
            class="alert-danger"
          >
            {{ errors.first("button_text") }}
          </div>
        </b-form-group>
        <b-form-group :label="$t('buttonLink')">
          <b-form-input
            name="button-link"
            v-validate="'required|min:3|max:50'"
            v-model="slide.button_link[language]"
          ></b-form-input>
          <div
            v-if="submitted && errors.has('button_link')"
            class="alert-danger"
          >
            {{ errors.first("button_link") }}
          </div>
        </b-form-group>
        <b-form-group :label="$t('openBlank')">
          <b-form-checkbox v-model="slide.target_blank"></b-form-checkbox>
        </b-form-group>
      </b-tab>
    </b-tabs>
    <b-form-group label-size="lg" :label="$t('backgroundImage')">
      <ImageUpload
        :title="$t('backgroundImage')"
        :url="slide.image"
        ref="upload"
        @updateImage="updateImage"
      />
    </b-form-group>
  </div>
</template>
<script>
import ImageUpload from "@/components/admin/ImageUpload.vue";
export default {
  props: ["slide", "index", "submitted"],
  components: { ImageUpload },
  computed: {
    languages() {
      return this.$store.state.Languages.all;
    },
  },
  methods: {
    updateImage(newUrl) {
      this.slide.image = newUrl;
    },
  },
};
</script>
