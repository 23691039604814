<template>
  <div>
    <b-button variant="secondary" class="mt-2 mb-2" @click="add">
      {{ $t("addBlock") }}
    </b-button>
    <draggable
      class="list-group mb-5"
      tag="ul"
      :list="object"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
    >
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <b-card
          class="list-group-item"
          v-for="(block, blockIndex) in object"
          :key="blockIndex"
          no-body
        >
          <ContentHeader v-if="block" :object="object" :index="blockIndex" />
          <b-collapse
            :id="`accordion-${blockIndex}`"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <!-- TYPE  -->
              <b-form-group :label="$t('type')">
                <b-form-select
                  @change="onTypeChange(blockIndex)"
                  v-model="block.type"
                  :options="blockTypes"
                ></b-form-select>
              </b-form-group>
              <!-- TAGS  -->
              <b-form-group :label="$t('tagDescription')">
                <b-form-tags v-model="block.tags"></b-form-tags>
              </b-form-group>

              <!-- Language attributes  -->
              <b-tabs content-class="mt-3" nav-class="no-padding">
                <b-tab
                  v-for="(language, langindex) in languages"
                  :key="langindex"
                >
                  <template #title>
                    <country-flag :country="language" size="normal" />
                  </template>
                  <b-form-group :label="$t('title')">
                    <b-form-input
                      v-model="block.title[language]"
                    ></b-form-input>
                  </b-form-group>
                  <!-- Form stuff -->
                  <b-form-group v-if="block.type == 'form'" label="Select Form">
                    <model-list-select
                      v-model="block.selectedForm"
                      :list="forms"
                      option-text="name"
                      option-value="id"
                      placeholder="Choose Form"
                    ></model-list-select>
                  </b-form-group>
                  <!-- Livestream stuff -->
                  <b-form-group
                    v-if="block.type == 'social'"
                    label="Select topic"
                  >
                    <socialpost-topic-select
                      v-model="block.topic"
                      option-text="id"
                      option-value="title"
                    ></socialpost-topic-select>
                  </b-form-group>
                  <b-form-group
                    v-if="block.type == 'livestream'"
                    label="Select livestream"
                  >
                    <model-list-select
                      v-model="block.selectedLivestream[language]"
                      :list="livestreams"
                      option-text="id"
                      option-value="id"
                      placeholder="Choose livestream"
                    ></model-list-select>
                  </b-form-group>
                  <b-form-group v-if="block.type == 'html'" label="Content">
                    <code-editor
                      language="html"
                      v-model="block.html[language]"
                    />
                  </b-form-group>
                  <!-- Banner stuff -->
                  <div v-if="block.type == 'banner'">
                    <b-form-group :label="$t('bannerImage')">
                      <ImageUpload
                        :title="$t('bannerImage')"
                        :url="block.image"
                        ref="upload"
                        @updateImage="
                          updateBannerImage(blockIndex, ...arguments)
                        "
                      />
                    </b-form-group>
                    <b-form-group :label="$t('description')">
                      <b-form-textarea
                        v-model="block.description[language]"
                      ></b-form-textarea>
                    </b-form-group>
                    <b-form-group :label="$t('buttonText')">
                      <b-form-input
                        v-model="block.button_text[language]"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox v-model="block.openBlank"
                        >Open blank</b-form-checkbox
                      >
                    </b-form-group>
                    <b-form-group :label="$t('buttonLink')">
                      <b-form-input
                        v-model="block.button_link[language]"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </b-tab>
              </b-tabs>
              <b-form-group
                :label="$t('amountOfEvents')"
                v-if="block.type === 'calendar'"
              >
                <b-form-input v-model="block.amount"></b-form-input>
              </b-form-group>
              <Table
                v-if="block.type == 'carousel'"
                :object="block.slides"
                :type="'slides'"
                :title="$t('slides')"
                :addText="$t('addSlide')"
                :detailTitle="$t('detailSlide')"
                :fields="[
                  'title.nl',
                  'description.nl',
                  'button_text.nl',
                  'image',
                  'actions',
                ]"
              />
              <Table
                v-if="block.type == 'items'"
                :object="block.blocks"
                :type="'items'"
                :title="$t('items')"
                :addText="$t('addItem')"
                :detailTitle="$t('detailItem')"
                :fields="[
                  'type',
                  'category.nl',
                  'title.nl',
                  'image',
                  'url',
                  'date',
                  'actions',
                ]"
              />
            </b-card-body>
          </b-collapse>
        </b-card>
      </transition-group>
    </draggable>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import ContentHeader from "@/components/admin/home/ContentHeader.vue";
import Table from "@/components/admin/home/Table.vue";
import ImageUpload from "@/components/admin/ImageUpload.vue";
import SocialpostTopicSelect from "@/components/admin/socialpost-topics/SocialpostTopicSelect.vue";
import { DashboardItem } from "@/models/dashboard-item.model";
import { ModelListSelect } from "vue-search-select";
import CodeEditor from "@/components/CodeEditor.vue";

export default {
  props: ["object"],
  components: {
    draggable,
    ContentHeader,
    CodeEditor,
    Table,
    ImageUpload,
    ModelListSelect,
    SocialpostTopicSelect,
  },
  data() {
    return {
      forms: [
        { id: 1, name: "Form 1" },
        { id: 2, name: "Form 2" },
        { id: 3, name: "Form 3" },
        { id: 4, name: "Form 4" },
      ],
      slideFields: [
        {
          name: `title.${this.$root.$i18n.locale}`,
          title: "Title",
          sortField: "Title",
        },
        {
          name: `description.${this.$root.$i18n.locale}`,
          title: "Description",
          sortField: "Description",
        },
        {
          name: `button_text.${this.$root.$i18n.locale}`,
          title: "Button text",
          sortField: "Button text",
        },
        { name: "image", title: "Image url" },
        { name: "actions", title: "Actions" },
      ],
      itemFields: [
        { name: "type", title: "Type" },
        {
          name: `title.${this.$root.$i18n.locale}`,
          title: "Title",
          sortField: "Title",
        },
        {
          name: `category.${this.$root.$i18n.locale}`,
          title: "Category",
          sortField: "Category",
        },
        "image",
        "url",
        "date",
        "actions",
      ],
      drag: false,
      blockTypes: [
        "carousel",
        "banner",
        "livestream",
        "items",
        "social",
        "nextEvent",
        // "form",
        "html",
        "calendar",
      ],
      livestreams: [],
    };
  },
  async created() {
    await this.fetchLivestreams();
    this.livestreams = this.$store.getters["Livestreams/allLivestreams"];
    console.log(this.livestreams);
  },
  methods: {
    add: function() {
      this.object.push(
        new DashboardItem(
          "",
          false,
          {},
          {},
          [],
          "",
          {},
          {},
          {},
          null,
          null,
          null,
          {}
        )
      );
    },
    updateBannerImage(blockIndex, newUrl) {
      this.object[blockIndex].image = newUrl;
    },
    async fetchLivestreams() {
      await this.$store.dispatch("Livestreams/fetchAll");
    },
    onTypeChange(index) {
      if (this.object[index].type == "carousel") {
        this.$set(
          this.object,
          index,
          new DashboardItem(
            "carousel",
            false,
            {},
            {},
            [],
            "",
            {},
            {},
            {},
            null,
            []
          )
        );
      } else if (this.object[index].type == "banner") {
        this.$set(
          this.object,
          index,
          new DashboardItem(
            "banner",
            false,
            {},
            {},
            [],
            "",
            {},
            {},
            {},
            null,
            null
          )
        );
        console.log(this.object);
      } else if (this.object[index].type == "items") {
        this.$set(
          this.object,
          index,
          new DashboardItem(
            "items",
            false,
            {},
            {},
            [],
            "",
            {},
            {},
            {},
            [],
            null
          )
        );
      } else if (this.object[index].type == "livestream") {
        this.$set(
          this.object,
          index,
          new DashboardItem(
            "livestream",
            false,
            {},
            {},
            [],
            "",
            {},
            {},
            {},
            [],
            null
          )
        );
      } else if (this.object[index].type == "calendar") {
        this.$set(
          this.object,
          index,
          new DashboardItem(
            "calendar",
            false,
            {},
            {},
            [],
            "",
            {},
            {},
            {},
            [],
            null
          )
        );
      }
    },
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale;
    },
    languages() {
      return this.$store.state.Languages.all;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group-item {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.list-group-item i {
  cursor: pointer;
}

.button {
  margin-top: 35px;
}
</style>
