










import BaseSelect from "@/components/BaseSelect.vue";
import { SocialpostTopic } from "@/models/socialpost-topic.model";
import SocialpostTopicsService from "@/services/SocialpostTopicsService";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { BaseSelect },
})
export default class SocialpostTopicSelect extends Vue {
  @VModel({ type: Array })
  data!: SocialpostTopic[];

  @Prop()
  multiple!: boolean;

  @Prop({
    default: false,
  })
  hideLabel!: boolean;

  @Prop({
    default: false,
  })
  useId!: boolean;

  reduce(x) {
    if (this.useId) {
      return x.id;
    }
    return x;
  }

  async fetchTopics(): Promise<SocialpostTopic[]> {
    return SocialpostTopicsService.fetchAllUnpaginated();
  }
}
