import { BaseModel } from "./base.model";

export class DashboardItem extends BaseModel {
  constructor(
    public type: string,
    public active: boolean,
    public title: { [lang: string]: string },
    public selectedLivestream: { [lang: string]: string },
    public tags?: [],
    public image?: string,
    public description?: { [lang: string]: string },
    public button_text?: { [lang: string]: string },
    public button_link?: { [lang: string]: string },
    public blocks?: [],
    public slides?: [],
    public amount?: number,
    public html?: { [lang: string]: string },
    public target_blank: boolean = false
  ) {
    super();
  }
}
